<template>
  <div class="inner-section">
    <card>
      <template v-slot:searchBody>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <template v-slot:headerTitle v-if="appDetail.save_status === 2">
        <h4 class="card-title">{{ $t('dealer.application_form') }}</h4>
      </template>
      <template v-slot:body>
          <div v-if="applications.length > 0">
            <Action :appDetail="appDetail" @enableRenew="onEnableRenew()" />
            <AppStep :appDetail.sync="appDetail" :applications="applications" @enableNew="onEnableNew()" />
          </div>
      </template>
    </body-card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('lrcpnServicePanel.formalin_license_application') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
        <!-- <router-link v-if="" to="formalin-license-application-form" class="btn-add"  v-b-modal.modal-form><i class="ri-add-fill"></i> {{ $t('globalTrans.add_new') }}</router-link> -->
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <b-row>
            <b-col md="12" class="table-responsive">
              <slot v-if="columns.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(applicant_name)="data">
                    {{ currentLocale == 'bn' ? data.item.applicant_name_bn : data.item.applicant_name_en }}
                  </template>
                  <template v-slot:cell(father_name)="data">
                    {{ currentLocale == 'bn' ? data.item.father_spouse_name_bn : data.item.father_spouse_name_en }}
                  </template>
                  <template v-slot:cell(telephone)="data">
                    {{ data.item.telephone_number | mobileNumber }}
                  </template>
                  <template v-slot:cell(type)="data">
                    <span class="badge badge-warning" v-if="data.item.app_type === 1 ">{{$t('globalTrans.new')}}</span>
                    <span class="badge badge-success" v-if="data.item.app_type === 2">{{$t('globalTrans.renew')}}</span>
                  </template>
                  <template v-slot:cell(status)="data">
                    <span class="badge badge-warning" v-if="data.item.save_status === 1">{{$t('globalTrans.draft')}}</span>
                    <span class="badge badge-success" v-if="data.item.status === 1 || data.item.status === 2 || ( data.item.status === 3 && data.item.approval_status  === 0)">{{$t('globalTrans.processing')}}</span>
                    <template v-if="data.item.approval_status === 3">
                      <span class="badge badge-warning" v-if="data.item.approval_status"> {{ getTypeName(data.item.approval_status ) }}</span>
                    </template>
                    <template v-else>
                      <span class="badge badge-danger" v-if="data.item.status === 5">{{$t('globalTrans.rejected')}}</span>
                    </template>
                    <span class="badge badge-success" v-if="data.item.status === 4">{{$t('globalTrans.approved')}}</span>
                  </template>
                  <template v-slot:cell(action)="data">
                    <!-- edit -->
                    <router-link v-if="data.item.save_status === 1" class="action-btn active view" variant=" iq-bg-warning mr-1" :title="$t('globalTrans.edit')" :to="`/lrcpn-service-panel/formalin-license-application-form/${data.item.id}`"><i class="ri-pencil-fill"></i></router-link>
                    <!-- details -->
                    <router-link :to="{ name: 'lrcpn_service_panel.formalin_license_application_details', params: {id: data.item.id} }" variant=" iq-bg-success mr-1" size="sm" @click="details(data.item)" class="action-btn edit" :title="$t('globalTrans.details')"><i class="ri-eye-fill"></i></router-link>
                    <!-- certificate view -->
                    <router-link v-if="data.item.status === 4" class="moc-action-btn moc-view-btn" :to="`formalin-license-application-certificate/${data.item.id}`" :title="$t('globalTrans.certificate')">
                      <i class="ri-file-paper-2-line"></i>
                    </router-link>
                    <!-- <b-button v-if="data.item.save_status === 2 && data.item.pay_status === 0" v-b-modal.modal-payments variant=" iq-bg-success mr-1" size="sm" @click="details(data.item)" class="action-btn bg-primary" :title="$t('globalTrans.payment')"><i class="ri-bank-card-line"></i></b-button> -->
                  </template>
                </b-table>
              </slot>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
    <b-modal id="modal-details" size="lg" :title="viewTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
     <Details :items="items" :key="viewitemId"/>
    </b-modal>
    <!-- <b-modal id="modal-payments" size="lg" :title="paymentTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
     <Payments :items="items" :key="viewitemId" :is_tab="0"/>
    </b-modal> -->
  </div>
</template>
<script>
// import Payments from './Payments.vue'
import Action from './Action.vue'
import AppStep from './AppStep.vue'
import Details from './Details.vue'
import RestApi, { lrcpnServiceBaseUrl } from '@/config/api_config'
import { applicationList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import './step.css'

export default {
  mixins: [ModalBaseMasterList],
  components: {
     Details, AppStep, Action
  },
  data () {
    return {
      lrcpnServiceBaseUrl: lrcpnServiceBaseUrl,
      sortBy: '',
      sortDesc: true,
      sortDirection: 'desc',
      search: {
      },
      appDetail: '',
      applications: [],
      enable_new: false,
      enable_renew: false,
      items: [],
      viewitemId: 0,
      labelData: [
        { labels: 'globalTrans.applicant_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '15%' } },
        { labels: 'globalTrans.application_id', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '15%' } },
        { labels: 'lrcpnServicePanel.father_spouse_name', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '15%' } },
        { labels: 'externalUser.telephone_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '15%' } },
        { labels: 'globalTrans.type', class: 'text-left', sortable: true, stickyColumn: false, show: '1', order: 6, thStyle: { width: '10%' } },
        { labels: 'globalTrans.status', class: 'text-left', sortable: true, stickyColumn: false, show: '1', order: 6, thStyle: { width: '10%' } },
        { labels: 'globalTrans.action', class: 'text-left', sortable: true, stickyColumn: false, show: '1', order: 7, thStyle: { width: '5%' } }
      ],
      actions: {
        edit: false,
        details: true,
        toogle: false,
        delete: false
      }
    }
  },
  created () {
    this.loadData()
  },
  watch: {
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    typeOfBusinessList () {
       return this.$store.state.ExternalUserService.eBizPanel.commonObj.typeOfBusinessList.filter(item => item.status === 1)
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'applicant_name' },
          { key: 'application_id' },
          { key: 'father_name' },
          { key: 'telephone' },
          { key: 'type' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'applicant_name' },
          { key: 'application_id' },
          { key: 'father_name' },
          { key: 'telephone' },
          { key: 'type' },
          { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    paymentTitle () {
      return this.$t('lrcpnServicePanel.application_payment')
    },
    viewTitle () {
      return this.$t('InformationTraining.business_directory') + ' ' + this.$t('globalTrans.details')
    }
  },
  methods: {
    onEnableNew () {
      this.enable_new = true
      this.$router.push({ name: 'lrcpn_service_panel.formalin_license_application_form' })
    },
    onEnableRenew () {
      this.enable_renew = true
      this.$router.push({ name: 'lrcpn_service_panel.formalin_license_application_form', params: { id: this.appDetail.id, isRenew: true } })
    },
    edit (item) {
        this.editItemId = item.id
    },
    details (item) {
      this.items = item
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(lrcpnServiceBaseUrl, applicationList, params)
      if (result.success) {
        this.appDetail = result.data
        this.applications = result.applications
        if (this.applications.length === 0) {
          this.$router.push({ name: 'lrcpn_service_panel.formalin_license_application_form' })
        }
        const listData = result.applications.map((item, index) => {
          return Object.assign({}, item, { serial: index })
        })
        this.$store.dispatch('setList', listData)
      } else {
        this.$store.dispatch('setList', [])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getTypeName (id) {
      const data = this.$store.state.ExternalUserService.lrcpnPanel.commonObj.actionTypeList.find(item => item.value === id)
      if (typeof data !== 'undefined') {
        return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
      } else {
        return ''
      }
    }
  }
}
</script>
