<template>
  <b-container fluid>
    <b-overlay>
      <b-col v-if="loadHistory" md="12" lg="12" sm="12">
         <b-skeleton-table
            :rows="4"
            :columns="4"
            :table-props="{ bordered: true, striped: true }"
          ></b-skeleton-table>
      </b-col>
      <b-col v-else md="12" lg="12" sm="12">
        <div>
          <b-tabs content-class="mt-3">
            <span v-for="(item, index) in history" :key="index">
              <b-tab :title="item.transaction_number">
                <div class="row">
                  <b-row class="text-right mb-2">
                    <b-col>
                        <b-button variant="primary" @click="pdfExport" class="mr-2">
                          <i class="ri-printer-line"></i> {{  $t('globalTrans.print') }}
                        </b-button>
                    </b-col>
                  </b-row>
                  <div class="col-md-12" id="moneyReceiptArea">
                    <table v-if="history.length" class="table table-bordered table-sm">
                      <tr>
                        <th colspan="3">{{  $t('dealer.applicant_name') }}</th>
                        <td colspan="4">{{ $i18n.locale === 'bn' ? application.applicant_name_bn : application.applicant_name_en }}</td>
                      </tr>
                      <tr>
                        <th colspan="3">{{  $t('globalTrans.application_id') }}</th>
                        <td colspan="4">{{ application.application_id }}</td>
                      </tr>
                      <tr>
                        <th colspan="3">{{  $t('dealer.transaction_no') }}</th>
                        <td colspan="4">{{ item.transaction_number }}</td>
                      </tr>
                      <tr>
                        <th colspan="3">{{  $t('globalTrans.date') }}</th>
                        <td colspan="4">{{ item.date | dateFormat }}</td>
                      </tr>
                      <tr>
                        <th colspan="7" class="text-center">{{ $t('dealer.payment_history') }}</th>
                      </tr>
                      <tr>
                          <th>{{ $t('globalTrans.type') }}</th>
                          <th colspan="2">{{ $t('teaGardenBtriService.bank_name') }} </th>
                          <th colspan="2">{{ $t('teaGardenBtriService.branch_name') }} </th>
                          <th colspan="2">{{ $t('dealer.fee_amount') }}</th>
                      </tr>
                      <tr>
                          <th>
                            <span v-if="application.app_type == 1">{{ $t('globalTrans.new') }}</span>
                            <span v-else>{{ $t('globalTrans.renew') }}</span>
                          </th>
                          <th colspan="2">{{ getBankName(item.bank_id) }} </th>
                          <th colspan="2">{{ getBranchName(item.branch_id) }} </th>
                          <th colspan="2">{{ $n(item.amount) }}</th>
                      </tr>
                    </table>
                    <slot v-else>
                        <b-tr>
                            <td colspan="3" class="text-center">Sorry, Data not found</td>
                        </b-tr>
                      </slot>
                  </div>
                </div>
              </b-tab>
            </span>
          </b-tabs>
        </div>
        <div class="row mt-3">
          <div class="col-md-12 text-right">
            <b-button variant="danger" class="mr-1 mt-3" @click="$bvModal.hide('receipt')">{{ $t('globalTrans.cancel') }}</b-button>
          </div>
        </div>
      </b-col>
    </b-overlay>
  </b-container>
</template>
<script>
import RestApi, { lrcpnServiceBaseUrl } from '@/config/api_config'
import { allAppReceipt } from '../../api/routes'

export default {
  props: ['application'],
  components: {
  },
  data () {
    return {
        loadHistory: false,
        history: [],
        receipt: {}
    }
  },
  created () {
    this.paymentHistory()
  },
  computed: {
    bankList: function () {
        return this.$store.state.CommonService.commonObj.bankList
    },
    branchList: function () {
        return this.$store.state.CommonService.commonObj.branchList
    }
  },
  methods: {
    setReceipt (item) {
      this.receipt = item
    },
    getBankName (id) {
        const bank = this.bankList.find(item => item.value === id)
        if (bank !== undefined) {
          return this.$i18n.locale === 'bn' ? bank.text_bn : bank.text_bn
        } else {
          return ''
        }
    },
    getBranchName (id) {
        const branch = this.branchList.find(item => item.value === id)
        if (branch !== undefined) {
          return this.$i18n.locale === 'bn' ? branch.text_bn : branch.text_bn
        } else {
          return ''
        }
    },
    async paymentHistory () {
      this.loadHistory = true
      const result = await RestApi.getData(lrcpnServiceBaseUrl, allAppReceipt + '/' + this.application.id)
      this.loadHistory = false
      if (result.success) {
        this.history = result.data
      } else {
        this.history = []
      }
    }
  }
}
</script>
