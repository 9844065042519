<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(savePayment)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
        <b-row>
          <!-- bank name  -->
        <b-col sm="6">
            <ValidationProvider name="Bank Name" vid="bank_id" rules="required|min_value:1">
            <b-form-group slot-scope="{ valid, errors }" label-for="bank_id">
                <template v-slot:label>
                {{ $t('teaGardenBtriService.bank_name') }} <span class="text-danger">*</span>
                </template>
                <b-form-select plain v-model="payment_info.bank_id" :options="bankList" id="bank_name"
                :state="errors[0] ? false : (valid ? true : null)">
                <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
                </b-form-select>
                <div class="invalid-feedback">
                {{ errors[0] }}
                </div>
            </b-form-group>
            </ValidationProvider>
         </b-col>
        <!-- branch name  -->
        <b-col sm="6">
            <ValidationProvider name="Branch Name" vid="branch_id" rules="required|min_value:1">
            <b-form-group slot-scope="{ valid, errors }" label-for="branch_id">
                <template v-slot:label>
                {{ $t('teaGardenBtriService.branch_name') }} <span class="text-danger">*</span>
                </template>
                <b-form-select plain v-model="payment_info.branch_id" :options="branchList" id="bank_name"
                :state="errors[0] ? false : (valid ? true : null)">
                <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
                </b-form-select>
                <div class="invalid-feedback">
                {{ errors[0] }}
                </div>
            </b-form-group>
            </ValidationProvider>
        </b-col>
         <!-- check_no -->
         <b-col sm="6">
            <ValidationProvider name="Check/ DD/ Chalan No" vid="check_no" rules="required">
            <b-form-group slot-scope="{ valid, errors }" label-for="check_no">
                <template v-slot:label>
                {{ $t('teaGardenBtriService.check_no') }} <span class="text-danger">*</span>
                </template>
                <b-form-input type="text" v-model="payment_info.check_no"
                :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                <div class="invalid-feedback">
                {{ errors[0] }}
                </div>
            </b-form-group>
            </ValidationProvider>
        </b-col>
        <!-- date  -->
        <b-col sm="6">
            <ValidationProvider name="Date" vid="date" rules="required">
            <b-form-group class="row" label-for="date" slot-scope="{ valid, errors }">
                <template v-slot:label>
                {{ $t('teaGardenBtriService.date') }} <span class="text-danger">*</span>
                </template>
                <date-picker id="date" v-model="payment_info.date" class="form-control"
                :placeholder="$t('globalTrans.select_date')"
                :state="errors[0] ? false : (valid ? true : null)"
                :class="errors[0] ? 'is-invalid' : ''"
                :config="config"
                :locale="currentLocale">
                </date-picker>
                <div class="invalid-feedback d-block">
                {{ errors[0] }}
                </div>
            </b-form-group>
            </ValidationProvider>
        </b-col>
          <!-- attachment  -->
          <b-col cols="12">
            <ValidationProvider name="Attachment" vid="attachment" rules="required">
            <b-form-group slot-scope="{ valid, errors }" label-for="attachment">
                <template v-slot:label>
                <span>{{ $t('teaGardenBtriService.attachment') }}</span>
                <span class="text-danger">*</span>
                </template>
                <div class="d-flex">
                <b-form-file accept="application/pdf,image/*" id="attachment" v-model="payment_info.attachment"
                    @change="onChange" :state="errors[0] ? false : (valid ? true : null)"
                    :placeholder="$t('globalTrans.attachment_placeholder')"
                    :drop-placeholder="$t('globalTrans.attachment_drop_placeholder')"></b-form-file>
                </div>
                <div class="invalid-feedback d-block">
                {{ errors[0] }}
                </div>
            </b-form-group>
            </ValidationProvider>
        </b-col>
        <b-col sm="12">
          <table class="table table-striped table-hover table-sm table-bordered">
          <tbody>
            <tr>
                <th>{{ $t('dealer.fee_name') }}</th>
                <th>{{ $t('globalTrans.amount') }}</th>
                <th>{{ $t('globalTrans.vat') }} (%) </th>
                <th>{{ $t('globalTrans.tax') }} (%) </th>
            </tr>
            <tr>
              <td>
                {{ getFeeName(payments.service_id) }}
              </td>
              <td>
                {{ $n( payments.amount) }}
              </td>
              <td>{{ $n(payments.vat) }}%</td>
              <td>{{ $n(payments.tax) }}%</td>
            </tr>
            <tr>
              <td colspan="1" class="text-right">
                {{ $t('globalTrans.total') }}
              </td>
              <td>
                {{ $n(payments.total_amount) }}
              </td>
            </tr>
          </tbody>
        </table>
        </b-col>
        </b-row>
        <b-row class="text-right">
          <b-col>
              <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
              <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-payments')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { lrcpnServiceBaseUrl } from '@/config/api_config'
import { appPaymentStore } from '../../../api/routes'
export default {
  name: 'Form',
  props: ['items', 'is_tab'],
  data () {
    return {
      valid: null,
      saveBtnName: this.$t('globalTrans.payment'),
      errors: [],
      payments: {},
      payment_info: {
        app_main_id: 0,
        attachment: '',
        amount: '',
        date: '',
        check_no: '',
        bank_id: 0,
        branch_id: 0
      },
      branchList: []
    }
  },
  created () {
    if (this.items.id) {
        this.payment_info.app_main_id = this.items.id
        const paymentInfo = this.paymentTypeList.find(item => item.value === this.items.app_type)
        this.payment_info.amount = paymentInfo.total_amount
        this.payments = paymentInfo
    }
  },
  watch: {
    currentLocale: function (newVal, oldVal) {
      if (newVal !== oldVal) {
          this.changeEnBn()
      }
    },
    'payment_info.bank_id': function (newVal, oldVal) {
        if (newVal !== 0) {
          this.payment_info.branch_id = 0
          this.getBranchList(newVal)
        } else {
          this.branchList = []
        }
      }
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    paymentServiceList () {
      return this.$store.state.commonObj.lrcpnPaymentServiceList
    },
    componentOrgList () {
        return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    },
    paymentTypeList () {
      return this.$store.state.ExternalUserService.lrcpnPanel.commonObj.paymentTypeList
    },
    bankList: function () {
        return this.$store.state.CommonService.commonObj.bankList
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    getFeeName (id) {
      const feeName = this.paymentServiceList.find(item => item.value === id)
      if (feeName !== undefined) {
        return this.$i18n.locale === 'bn' ? feeName.text_bn : feeName.text_en
      } else {
        return ''
      }
    },
    onChange (event) {
        const input = event.target
        if (input.files && input.files[0]) {
            const reader = new FileReader()
            reader.onload = (e) => {
                this.payment_info.attachment = e.target.result
            }
            reader.readAsDataURL(input.files[0])
        } else {
            this.payment_info.attachment = ''
        }
    },
    changeEnBn () {
    },
    getBranchList (bankId) {
        this.branchList = this.$store.state.CommonService.commonObj.branchList.filter(item => (item.bank_id === bankId))
    },
    async savePayment () {
        var check = await this.$refs.form.validate()
        if (check) {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            const loadingState = { loading: false, listReload: false }
            const result = await RestApi.postData(lrcpnServiceBaseUrl, appPaymentStore + '/' + this.payment_info.app_main_id, this.payment_info)

            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)

            if (result.success) {
                this.$store.dispatch('LrcpnService/mutateCommonObj', { hasDropdownLoaded: false })
                this.$toast.success({
                    title: this.$t('globalTrans.success'),
                    message: this.items.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })
                this.$bvModal.hide('modal-payments')
                if (this.is_tab === 1) {
                  this.$router.push({ name: 'lrcpn_service_panel.formalin_import_license_application' })
                }
            } else {
                this.$refs.form.setErrors(result.errors)
            }
        }
      }
   }
}
</script>
