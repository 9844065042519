<template>
  <b-col md="10" lg="10" sm="12" offset-md="1">
    <b-overlay :show="loading">
      <table class="table table-striped table-hover table-sm table-bordered">
        <tbody>
          <tr>
              <th>{{ $t('dealer.fee_name') }}</th>
              <th>{{ $t('globalTrans.amount') }}</th>
              <th>{{ $t('globalTrans.vat') }} (%) </th>
              <th>{{ $t('globalTrans.tax') }} (%) </th>
          </tr>
          <tr>
            <td>
              {{ getFeeName(payments.service_id) }}
            </td>
            <td>
              {{ $n( payments.amount) }}
            </td>
            <td>{{ $n(payments.vat) }}%</td>
            <td>{{ $n(payments.tax) }}%</td>
          </tr>
          <tr>
            <td colspan="1" class="text-right">
              {{ $t('globalTrans.total') }}
            </td>
            <td>
              {{ $n(payments.total_amount) }}
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row mt-3">
          <div class="col-md-12 text-right">
              <b-button variant="primary" class="mr-1 mt-3 btn-sm" @click="saveFormData()">{{ $t('dealer.payment') }}</b-button>
              <b-button variant="danger" class="mr-1 mt-3 btn-sm" @click="$bvModal.hide('modal-payments')">{{ $t('globalTrans.cancel') }}</b-button>
          </div>
      </div>
    </b-overlay>
  </b-col>
</template>
<script>
import RestApi, { lrcpnServiceBaseUrl } from '@/config/api_config'
import { paymentOnlineStore } from '../../../api/routes'
export default {
  props: ['payments', 'items'],
  name: 'PaymentOnline',
  components: {
  },
  data () {
    return {
      loading: false
    }
  },
  created () {
  },
  computed: {
    commonProfile () {
      return this.$store.state.Auth.commonProfile
    },
    paymentServiceList () {
      return this.$store.state.commonObj.lrcpnPaymentServiceList
    },
    auth () {
      return this.$store.state.Auth.authUser
    }
  },
  methods: {
    getFeeName (id) {
      const feeName = this.paymentServiceList.find(item => item.value === id)
      if (feeName !== undefined) {
        return this.$i18n.locale === 'bn' ? feeName.text_bn : feeName.text_en
      } else {
        return ''
      }
    },
    async saveFormData () {
          let result = null
          this.loading = true
          const paymentData = {
            app_main_id: this.items.id,
            pay_type: this.payments.pay_type,
            applicant_name: this.items.applicant_name_en,
            mobile_no: this.items.telephone_number,
            address: this.items.permanent_village_name_en,
            payments: this.payments,
            amount: this.payments.total_amount
          }
          result = await RestApi.postData(lrcpnServiceBaseUrl, paymentOnlineStore, paymentData)
          this.loading = false
          if (result.success) {
            this.$toast.success({
              title: 'Success',
              message: result.message
            })
            this.$bvModal.hide('modal-payments')
              window.location = result.url
          } else {
            // this.$refs.form.setErrors(result.errors)
            this.$toast.error({
              title: 'Error',
              message: result.message
            })
          }
    }
  }
}
</script>
