<template>
<div>
  <b-row>
    <b-col md="12" lg="12" sm="12" class="mb-4">
      <div class="text-center mt-2">
        <b-form-radio-group
          id="payment_type"
          v-model="type"
          :options="paidTypeList"
        ></b-form-radio-group>
      </div>
    </b-col>
     <!-- <pre>sdfsd{{type}}{{paymentList}}</pre> -->
    <component :is="comp" :payments="payment_info" :items="application"></component>
  </b-row>
</div>
</template>
<script>
import OnlinePayment from './Payments/PaymentOnline.vue'
import OfflinePayment from './Payments/PaymentOffline.vue'

export default {
  props: ['application'],
  name: 'Payment',
  components: {
    OnlinePayment,
    OfflinePayment
  },
  data () {
    return {
      old_app_loading: false,
      comp: 'OnlinePayment',
      type: 1,
      payment_info: {},
      isRenewTime: false,
      isLateTime: false,
      isExpiredTime: false
    }
  },
    watch: {
    type: function (n, o) {
      if (n !== o) {
        if (n === 1) {
          this.comp = 'OnlinePayment'
        } else {
          this.comp = 'OfflinePayment'
        }
      }
    }
  },
  computed: {
    paidTypeList () {
      return [
        {
          text: this.$i18n.locale === 'bn' ? 'অনলাইন' : 'Online',
          value: 1
        },
        {
          text: this.$i18n.locale === 'bn' ? 'অফলাইন' : 'Offline',
          value: 2
        }
      ]
    },
    paymentTypeList () {
      return this.$store.state.ExternalUserService.lrcpnPanel.commonObj.paymentTypeList
    }
  },
  created () {
    if (this.application.id) {
        if (this.application.id) {
          const paymentInfo = this.paymentTypeList.find(item => item.value === this.application.app_type)
          paymentInfo.app_main_id = this.application.id
          paymentInfo.pay_type = this.type
          this.payment_info = paymentInfo
        }
    }
  },
  methods: {
  }
}
</script>
