<template>
  <div>
    <b-row class="mb-3">
      <b-col sm="12">
        <div class="mt-1">
          <div>
            <h6 class="mb-3 tag">{{ $t('dealer.actions') }}</h6>
          </div>
          <!-- payment button -->
          <b-button v-if="appDetail.pay_status === 0 && appDetail.save_status === 2 && appDetail.app_type === 1" v-b-modal.modal-payments class="btn-sm" variant="warning">{{ $t('dealer.pay') }}</b-button>&nbsp;
          <!-- receipt button -->
          <b-button v-if="appDetail.pay_status > 0" v-b-modal.receipt class="btn-sm" variant="primary">{{ $t('dealer.receipt') }}</b-button>&nbsp;
          <!-- review button -->
          <!-- <b-button v-if="appDetail.review_status === 0" v-b-modal.modal-form class="btn-sm" variant="success">{{ $t('globalTrans.review') }}</b-button>&nbsp; -->
          <!-- renew button -->
          <b-button v-if="isRenewButton" @click="enableRenew()" class="btn-sm" variant="info">{{ $t('dealer.renew') }} </b-button>&nbsp;
          <!-- expire status -->
          <b-button v-if="isExpiredStatus" class="btn-sm" variant="info">{{ $t('dealer.expire_status')}} </b-button>
        </div>
      </b-col>
    </b-row>
    <div>
    <b-modal id="modal-payments" size="lg" :title="$t('dealer.payment')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger" ref="payment-modal">
      <Payments :application="appDetail" />
    </b-modal>
    <!-- <b-modal id="modal-form" size="lg" :title="$t('globalTrans.review')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Review :application="appDetail" />
    </b-modal> -->
    <b-modal id="receipt" size="lg" :title="$t('dealer.receipt')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Receipt :application="appDetail" />
    </b-modal>
  </div>
  </div>
</template>
<script>
import Payments from './Payment.vue'
// import Review from './Review.vue'
import Receipt from './Receipt.vue'
import moment from 'moment'
import { dateFormat } from '../../../../../../utils/fliter'

export default {
  props: ['appDetail'],
  name: 'Action',
  components: {
    Payments,
    Receipt
  },
  data () {
    return {
      recruitmentDelears: [],
      loading: false,
      isRenewButton: false,
      isExpiredStatus: false
    }
  },
  created () {
    if (this.appDetail.pay_status === 0 && this.appDetail.approval_status === 1 && this.appDetail.status === 4) {
       const lastUpdateDate = this.appDetail.updated_at
       const initialDate = new Date(lastUpdateDate)
       const nextMonthDate = new Date(initialDate)
        nextMonthDate.setMonth(initialDate.getMonth() + 1)
       this.agreementDeadlineDate = nextMonthDate.toISOString()
       if (nextMonthDate >= new Date()) {
        this.agreementDeadline = false
       } else {
        this.agreementDeadline = true
       }
    }
    this.checkRenewStatus()
  },
  computed: {
    currentLocale () {
        return this.$i18n.locale
    }
  },
  methods: {
    getDateFormat (date) {
       dateFormat(date)
    },
    checkRenewStatus () {
      const expiredDate = this.appDetail.expire_date
      const currentDate = moment().format('YYYY-MM-DD')
      const expireNextDate = moment(expiredDate).add(1, 'day').format('YYYY-MM-DD')
      const expirePrevMonthDate = moment(expireNextDate).subtract(1, 'month').format('YYYY-MM-DD')
      const expireNextYearDate = moment(expiredDate).add(365, 'day').format('YYYY-MM-DD')
      const isBetween = moment(currentDate).isBetween(expirePrevMonthDate, expireNextYearDate, undefined, '[]')
      if (moment(currentDate).isAfter(expireNextYearDate)) {
        this.isExpiredStatus = true
      } else {
        this.isExpiredStatus = false
      }
      if (isBetween) {
        this.isRenewButton = true
      } else {
        this.isRenewButton = false
      }
    },
    enableRenew () {
      this.$emit('enableRenew')
    }
  }
}
</script>
